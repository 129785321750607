import { VAvatar } from 'vuetify/lib/components/VAvatar';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VExpansionPanel } from 'vuetify/lib/components/VExpansionPanel';
import { VExpansionPanelContent } from 'vuetify/lib/components/VExpansionPanel';
import { VExpansionPanelHeader } from 'vuetify/lib/components/VExpansionPanel';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VListItemAvatar } from 'vuetify/lib/components/VList';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VExpansionPanel,[_c(VExpansionPanelHeader,{staticClass:"pa-0 pr-2",scopedSlots:_vm._u([{key:"actions",fn:function(){return [_c('div',{staticClass:"d-flex justify-center align-center align-content-center"},[_c('div',{staticClass:"pr-1"},[(!_vm.isValid)?_c(VIcon,{attrs:{"small":"","color":"error"}},[_vm._v(" fa-exclamation-circle ")]):_vm._e()],1),_c('div',{staticClass:"pr-1"},[(_vm.hasContent)?_c(VIcon,{attrs:{"small":"","color":_vm.getColor}},[_vm._v(" fa-edit ")]):_vm._e()],1),_c(VIcon,{attrs:{"color":_vm.getColor}},[_vm._v(" $expand ")])],1)]},proxy:true}])},[_c(VCardTitle,{staticClass:"pa-0"},[_c(VListItemAvatar,[_c(VAvatar,{attrs:{"color":_vm.getColor,"size":"35"}},[_c('span',{staticClass:"white--text body-1"},[_vm._v("AR")])])],1),_c('h4',{class:(_vm.getColor + "--text")},[_vm._v(" Access Control & Routing ")])],1)],1),_c(VExpansionPanelContent,[_c(VRow,{attrs:{"no-gutters":""}},[_c(VCol,{staticClass:"pr-1 pb-2",attrs:{"cols":"6"}},[_c('ClusterConfigItemCard',{attrs:{"config":_vm.cluster.authorization,"title":"Authorization","show":_vm.show}})],1),_c(VCol,{staticClass:"pl-1 pb-2",attrs:{"cols":"6"}},[_c('ClusterConfigItemCard',{attrs:{"config":_vm.cluster.authentication,"title":"Authentication","show":_vm.show}})],1),_c(VCol,{staticClass:"pb-1 pr-1",attrs:{"cols":"6"}},[_c('ClusterConfigItemCard',{attrs:{"config":_vm.cluster.routing,"title":"Routing","show":_vm.show}})],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }