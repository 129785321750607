<template>
  <router-view :key="$route.fullPath"> </router-view>
</template>

<script>
export default {
  name: "IntegrationsPage",
  components: {},
  data: function() {
    return {};
  }
};
</script>

<style scoped></style>
