import { VAvatar } from 'vuetify/lib/components/VAvatar';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VForm } from 'vuetify/lib/components/VForm';
import { VListItemAvatar } from 'vuetify/lib/components/VList';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VCard,{staticClass:"pa-0",attrs:{"elevation":"1"}},[_c(VCardTitle,{staticClass:"pa-0"},[_c(VListItemAvatar,[_c(VAvatar,{attrs:{"color":_vm.getColor,"size":"30"}},[_c('span',{staticClass:"white--text body-1"},[_vm._v(_vm._s(_vm.initial))])])],1),_c('h4',{class:(_vm.getColor + "--text")},[_vm._v(" "+_vm._s(_vm.title)+" ")])],1),_c(VCardText,{staticClass:"py-2"},[(_vm.toShow)?_c(VForm,{ref:"form",model:{value:(_vm.config.isValid),callback:function ($$v) {_vm.$set(_vm.config, "isValid", $$v)},expression:"config.isValid"}},[_c('v-jsf',{attrs:{"schema":_vm.config.schema,"options":_vm.config.options},on:{"change":_vm.validateForm},model:{value:(_vm.config.model),callback:function ($$v) {_vm.$set(_vm.config, "model", $$v)},expression:"config.model"}})],1):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }