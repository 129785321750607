import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VExpansionPanels } from 'vuetify/lib/components/VExpansionPanel';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VSpacer } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VCard,[_c(VCardTitle,{staticClass:"row-pointer pa-1 pl-2"},[_c('h3',{class:(_vm.getColor + "--text pr-1")},[_vm._v(" Advanced ")]),_c(VSpacer),_c('div',{staticClass:"d-flex justify-center align-center align-content-center"},[_c('div',{staticClass:"pr-2"},[(!_vm.isValid)?_c(VIcon,{attrs:{"color":"error"}},[_vm._v(" fa-exclamation-circle ")]):_vm._e()],1),_c('div',{staticClass:"pr-1"},[(_vm.hasEdits)?_c(VIcon,{attrs:{"color":_vm.getColor}},[_vm._v(" fa-edit ")]):_vm._e()],1),_c('div',{staticClass:"pr-2"},[_c(VBtn,{attrs:{"icon":""},on:{"click":function($event){$event.stopPropagation();return _vm.$emit('clear')}}},[(_vm.hasEdits)?_c(VIcon,{attrs:{"color":_vm.getColor}},[_vm._v(" fa-redo ")]):_vm._e()],1)],1)])],1),_c(VCardText,{directives:[{name:"show",rawName:"v-show",value:(_vm.showAdvanced),expression:"showAdvanced"}]},[_c(VExpansionPanels,{attrs:{"flat":""}},[_c('ClusterInterfacesSecurityPanel',{attrs:{"cluster":_vm.cluster,"show":_vm.toShow}}),_c('ClusterAccessControlRoutingPanel',{attrs:{"cluster":_vm.cluster,"show":_vm.toShow}}),_c('ClusterImageVolumePanel',{attrs:{"cluster":_vm.cluster,"show":_vm.toShow}}),_c('ClusterHealthResourcesNodesPanel',{attrs:{"cluster":_vm.cluster,"show":_vm.toShow}}),_c('ClusterStoreQueuesPanel',{attrs:{"cluster":_vm.cluster,"show":_vm.toShow}})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }