import { VAvatar } from 'vuetify/lib/components/VAvatar';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VExpansionPanel } from 'vuetify/lib/components/VExpansionPanel';
import { VExpansionPanelContent } from 'vuetify/lib/components/VExpansionPanel';
import { VExpansionPanelHeader } from 'vuetify/lib/components/VExpansionPanel';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VListItemAvatar } from 'vuetify/lib/components/VList';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VExpansionPanel,[_c(VExpansionPanelHeader,{staticClass:"pa-0 pr-2",scopedSlots:_vm._u([{key:"actions",fn:function(){return [_c('div',{staticClass:"d-flex justify-center align-center align-content-center"},[_c('div',{staticClass:"pr-1"},[(!_vm.isValid)?_c(VIcon,{attrs:{"small":"","color":"error"}},[_vm._v(" fa-exclamation-circle ")]):_vm._e()],1),_c('div',{staticClass:"pr-1"},[(_vm.hasContent)?_c(VIcon,{attrs:{"small":"","color":_vm.getColor}},[_vm._v(" fa-edit ")]):_vm._e()],1),_c(VIcon,{attrs:{"color":_vm.getColor}},[_vm._v(" $expand ")])],1)]},proxy:true}])},[_c(VCardTitle,{staticClass:"pa-0"},[_c(VListItemAvatar,[_c(VAvatar,{attrs:{"color":_vm.getColor,"size":"35"}},[_c('span',{staticClass:"white--text body-1"},[_vm._v("IS")])])],1),_c('h4',{class:(_vm.getColor + "--text")},[_vm._v(" Interfaces & Security ")])],1)],1),_c(VExpansionPanelContent,[_c(VRow,{attrs:{"no-gutters":""}},[_c(VCol,{staticClass:"pr-1"},[_c('ClusterConfigItemCard',{attrs:{"config":_vm.cluster.grpcInterface,"title":"GRPC","show":_vm.show}})],1),_c(VCol,{staticClass:"pr-1 pl-1"},[_c('ClusterConfigItemCard',{attrs:{"config":_vm.cluster.restInterface,"title":"REST","show":_vm.show}})],1),_c(VCol,{staticClass:"pr-1 pl-1"},[_c('ClusterConfigItemCard',{attrs:{"config":_vm.cluster.apiInterface,"title":"API","show":_vm.show}})],1)],1),_c(VRow,{staticClass:"mt-2",attrs:{"no-gutters":""}},[_c(VCol,{staticClass:"pr-1",attrs:{"cols":"8"}},[_c('ClusterConfigItemCard',{attrs:{"config":_vm.cluster.security,"title":"Security","show":_vm.show}})],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }