import { VAvatar } from 'vuetify/lib/components/VAvatar';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VImg } from 'vuetify/lib/components/VImg';
import { VMain } from 'vuetify/lib/components/VMain';
import { VSnackbar } from 'vuetify/lib/components/VSnackbar';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VToolbar } from 'vuetify/lib/components/VToolbar';
import Scroll from 'vuetify/lib/directives/scroll';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VMain,{staticClass:"secondary lighten-5"},[_c(VSnackbar,{attrs:{"timeout":_vm.toast.timeout,"color":_vm.toast.color,"top":""},model:{value:(_vm.toast.show),callback:function ($$v) {_vm.$set(_vm.toast, "show", $$v)},expression:"toast.show"}},[_vm._v(" "+_vm._s(_vm.toast.message)+" "),(_vm.toast.timeout === 0)?_c(VBtn,{attrs:{"color":"white","text":""},on:{"click":function($event){_vm.toast.show = false}}},[_vm._v("Close")]):_vm._e()],1),_c(VBtn,{directives:[{def: Scroll,name:"scroll",rawName:"v-scroll",value:(_vm.onScroll),expression:"onScroll"},{name:"show",rawName:"v-show",value:(_vm.fab),expression:"fab"}],attrs:{"fab":"","dark":"","fixed":"","bottom":"","right":"","color":"primary"},on:{"click":_vm.toTop}},[_c(VIcon,[_vm._v("fa-arrow-up")])],1),_c(VToolbar,{attrs:{"color":"primary","extended":""}}),_c(VCol,{staticClass:"px-4"},[_c(VCard,{staticClass:"container d-flex flex-column justify-space-between align-stretch align-content-space-around pt-0 pl-0",staticStyle:{"margin-top":"-102px"},attrs:{"rounded":"","min-height":"90vh"}},[_c(VCardText,[_c('div',{staticClass:"d-flex justify-start align-end align-content-end"},[_c(VAvatar,{attrs:{"size":"65"},on:{"click":function($event){return _vm.$router.push("/")}}},[_c(VImg,{attrs:{"src":require("@/assets/logo.svg"),"alt":"logo"}})],1),_c('h1',{staticClass:"primary--text pl-2"},[_vm._v(" KubeMQ Build & Deploy ")]),_c(VSpacer),_c(VBtn,{staticClass:"mb-n1",attrs:{"href":"https://kubemq.io","text":"","large":"","rounded":"","color":"primary"}},[_c(VIcon,{staticClass:"pr-2"},[_vm._v("fa-home")]),_c('h3',[_vm._v("KubeMQ.io")])],1)],1)]),_c('router-view',{key:_vm.$route.fullPath})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }