import { VAvatar } from 'vuetify/lib/components/VAvatar';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VForm } from 'vuetify/lib/components/VForm';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VListItemAvatar } from 'vuetify/lib/components/VList';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"d-flex justify-center align-content-center align-center"},[_c('div',{staticClass:"side"},[_c(VCard,{staticClass:"pa-0"},[_c(VCardTitle,{staticClass:"pa-0"},[_c(VListItemAvatar,[_c(VAvatar,{attrs:{"color":"secondary","size":"30"}},[_c('span',{staticClass:"white--text body-1"},[_vm._v(_vm._s(_vm.source.Initial))])])],1),_c('h4',{staticClass:"secondary--text"},[_vm._v(" "+_vm._s(_vm.source.Title)+" ")])],1),_c(VCardText,[(_vm.toShow)?_c(VForm,{ref:"formSource",model:{value:(_vm.source.IsModelValid),callback:function ($$v) {_vm.$set(_vm.source, "IsModelValid", $$v)},expression:"source.IsModelValid"}},[_c('v-jsf',{attrs:{"schema":_vm.source.Schema,"options":_vm.options},model:{value:(_vm.source.Model),callback:function ($$v) {_vm.$set(_vm.source, "Model", $$v)},expression:"source.Model"}})],1):_vm._e()],1)],1)],1),_c('div',{staticClass:"pl-3 flex-grow-1"},[_c(VListItemAvatar,{attrs:{"color":"secondary","size":"30"}},[_c(VIcon,{attrs:{"size":"20","color":"white"}},[_vm._v(" fa-arrow-right ")])],1)],1),_c('div',{staticClass:"side"},[_c(VCard,{staticClass:"pa-0"},[_c(VCardTitle,{staticClass:"pa-0"},[_c(VListItemAvatar,[_c(VAvatar,{attrs:{"color":"secondary","size":"30"}},[_c('span',{staticClass:"white--text body-1"},[_vm._v(_vm._s(_vm.target.Initial))])])],1),_c('h4',{staticClass:"secondary--text"},[_vm._v(" "+_vm._s(_vm.target.Title)+" ")])],1),_c(VCardText,[(_vm.toShow)?_c(VForm,{ref:"formTarget",model:{value:(_vm.target.IsModelValid),callback:function ($$v) {_vm.$set(_vm.target, "IsModelValid", $$v)},expression:"target.IsModelValid"}},[_c('v-jsf',{attrs:{"schema":_vm.target.Schema,"options":_vm.options},model:{value:(_vm.target.Model),callback:function ($$v) {_vm.$set(_vm.target, "Model", $$v)},expression:"target.Model"}})],1):_vm._e()],1)],1)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }