import { VAvatar } from 'vuetify/lib/components/VAvatar';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VImg } from 'vuetify/lib/components/VImg';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemSubtitle } from 'vuetify/lib/components/VList';
import { VSpacer } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"d-flex flex-grow-1 justify-space-between align-center align-content-space-between"},[_c(VSpacer),_c('div',{staticClass:"d-flex justify-end align-content-center align-center col-12"},[_c(VSpacer),_c('div',{staticClass:"col-5 pr-2"},[_c(VImg,{attrs:{"src":require("@/assets/ship.svg"),"alt":"ship"}})],1),_c('div',{staticClass:"col-6 pl-2"},[_c(VList,{attrs:{"elevation":"1","two-line":""}},[_vm._l((_vm.menu),function(item,index){return [(item.divider)?_c(VDivider,{key:index,attrs:{"inset":""}}):_c(VListItem,{key:item.title,staticClass:"col-12",attrs:{"link":"","to":item.link}},[_c('div',{staticClass:"col-1 pa-0"},[_c(VAvatar,{attrs:{"size":"48"}},[_c(VImg,{attrs:{"src":item.image}})],1)],1),_c('div',{staticClass:"col-10"},[_c(VCardTitle,{staticClass:"pa-0 secondary--text"},[_vm._v(" "+_vm._s(item.title)+" ")]),_c(VListItemSubtitle,{staticClass:"secondary--text"},[_vm._v(" "+_vm._s(item.subTitle)+" ")])],1),_c('div',{staticClass:"col-1 pa-0"},[_c(VIcon,{attrs:{"right":"","big":"","color":"secondary"}},[_vm._v(" fa-angle-right ")])],1)])]})],2)],1),_c(VSpacer)],1),_c(VSpacer)],1)}
var staticRenderFns = []

export { render, staticRenderFns }