import { VAutocomplete } from 'vuetify/lib/components/VAutocomplete';
import { VAvatar } from 'vuetify/lib/components/VAvatar';
import { VChip } from 'vuetify/lib/components/VChip';
import { VChipGroup } from 'vuetify/lib/components/VChipGroup';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemAvatar } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemSubtitle } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c(VAutocomplete,{ref:"autocomplete",attrs:{"dense":"","items":_vm.connectors,"filter":_vm.customFilter,"flat":"","rounded":"","cache-items":"","no-data-text":"No integrations were found for this query","hide-details":"","placeholder":"Type for available integrations","prepend-inner-icon":"fa-search","filled":""},on:{"change":_vm.add},scopedSlots:_vm._u([{key:"selection",fn:function(data){return [_c(VListItem,{staticClass:"pa-0 col-12",attrs:{"dense":""}},[_c(VListItemAvatar,{attrs:{"color":"primary","size":"25"}},[_c('span',{staticClass:"white--text"},[_vm._v(_vm._s(data.item.getInitial()))])]),_c(VListItemContent,{staticClass:"pa-0"},[_c(VListItemTitle,[_c('h4',{staticClass:"secondary--text font-weight-bold pr-2"},[_vm._v(" "+_vm._s(data.item.name)+" ")])])],1)],1)]}},{key:"item",fn:function(data){return [_c(VList,{staticClass:"col-12"},[_c(VListItem,{staticClass:"pa-0",attrs:{"dense":""}},[_c(VListItemAvatar,[_c(VAvatar,{attrs:{"color":"secondary","size":"40"}},[_c('span',{staticClass:"white--text headline"},[_vm._v(_vm._s(data.item.getInitial()))])])],1),_c(VListItemContent,{staticClass:"pa-0"},[_c(VListItemTitle,[_c('h2',{staticClass:"secondary--text"},[_vm._v(" "+_vm._s(data.item.title)+" ")])]),_c(VListItemSubtitle,[_c('span',{staticClass:"secondary--text body-1"},[_vm._v(_vm._s(data.item.category))])]),_c(VListItemSubtitle,[_c(VChipGroup,{attrs:{"column":""}},_vm._l((data.item.tags),function(tag,index){return _c(VChip,{key:'b' + tag + index,attrs:{"small":"","color":"primary","outlined":""}},[_vm._v(" "+_vm._s(tag)+" ")])}),1)],1)],1)],1)],1)]}}]),model:{value:(_vm.selectedItem),callback:function ($$v) {_vm.selectedItem=$$v},expression:"selectedItem"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }