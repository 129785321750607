import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSwitch } from 'vuetify/lib/components/VSwitch';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"px-4 pb-4 d-flex flex-grow-1 flex-column justify-start align-start align-content-space-between"},[_c('div',{staticClass:"col-12 pb-0"},[_c('builder-title',{attrs:{"title":_vm.getTitle,"show-back":"","show-save":""},on:{"save":_vm.save,"back":_vm.cancel}})],1),_c('div',{staticClass:"col-12 pb-0"},[_c(VCard,{attrs:{"flat":"","tile":""}},[_c(VCardText,[_c(VRow,{staticClass:"flex-column"},[_c(VCol,{staticClass:"pb-2 pt-0",attrs:{"cols":"6"}},[_c(VTextField,{ref:"inputName",attrs:{"clearable":"","label":"Integration Name","rules":[this.validateBindingName],"error":_vm.errorState},model:{value:(_vm.bindingModel.Name),callback:function ($$v) {_vm.$set(_vm.bindingModel, "Name", $$v)},expression:"bindingModel.Name"}})],1),_c(VCol,{staticClass:"py-0",attrs:{"cols":"12"}},[_c('IntegrationsBindingsProperties',{ref:"properties",attrs:{"binding":_vm.bindingModel,"options":_vm.options,"show":_vm.show}})],1),_c(VCol,{staticClass:"py-0",attrs:{"cols":"12"}},[_c(VSwitch,{staticClass:"mt-1",attrs:{"flat":"","color":_vm.getMiddlewareColor},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('h2',{class:(_vm.getMiddlewareColor + "--text")},[_vm._v("Middlewares")])]},proxy:true}]),model:{value:(_vm.setMiddleware),callback:function ($$v) {_vm.setMiddleware=$$v},expression:"setMiddleware"}}),(_vm.setMiddleware)?_c(VCardText,{staticClass:"pa-0 pb-2"},[_c('IntegrationsBindingMiddlewares',{attrs:{"config":_vm.bindingModel.Middlewares,"show":_vm.show}})],1):_vm._e()],1)],1)],1)],1)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }