<template>
  <v-app id="inspire">
    <default-layout></default-layout>
  </v-app>
</template>

<script>
import DefaultLayout from "@/layouts/DefaultLayout";

export default {
  components: { DefaultLayout },
  data: function() {
    return {};
  },
  computed: {}
};
</script>
<style scoped></style>
